import React from 'react'

import ModalLanguage from './ModalLanguage'

const LanguagesEdit = (props) => {

  const {
    languages,
    onModalOpen
  } = props

  return (
    <>
    <ModalLanguage
      {...props}
    />
    <div>
      <div className="admin-languages">
        {languages.map(item => (
          <div className="admin-image-item">
            <img source={item.imageUrl}></img>
            <button className="admin-image-item__button">eliminar</button>
          </div>
        ))}
      </div>
      <button
        style={{}}
        className='shared-button shared-button--second'
        onClick = { () => onModalOpen(true)}
      >
        Adicionar linguagem
      </button>
    </div>
    </>
  ) 
}

export default LanguagesEdit